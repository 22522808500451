import React, { useContext, useEffect, useState, useRef } from 'react';
import { ThemeContext } from 'styled-components';
import { useIntl } from 'react-intl';
import '@formatjs/intl-displaynames/polyfill';
import '@formatjs/intl-displaynames/locale-data/en';
import { DefaultTemplate } from '@luigiclaudio/ga-baseline-ui/template';
import { Container, Row, Col } from '@luigiclaudio/ga-baseline-ui/layout';
import {
    TransitionItem,
    useHasMounted,
    Loader,
    HelmetDetails,
} from '@luigiclaudio/ga-baseline-ui/helpers';
import { GatsbyA } from '@luigiclaudio/ga-baseline-ui/typography';
import { AlertFeedback } from '@luigiclaudio/ga-baseline-ui/alert';
import productTypeList from '../data/productTypeList.json';
import BrandHeader from '../components/app/brandHeader/BrandHeader';
import BrandTitle from '../components/app/BrandTitle';
import BrandHeaderOffset from '../components/app/brandHeader/BrandHeaderOffset';
import BrandContext from '../components/context/BrandContext';
import brandDetailsOnStageInitialState from '../components/context/brandDetailsOnStageInitialState';
import BrandDetails from '../components/app/BrandDetails';
import brandUtils from '../utils/brandUtils';
import countryUtils from '../utils/countryUtils';
import pageContextPropTypes from '../prop-types/pageContextPropTypes';
import useAppUser from '../services/useAppUser';
import appConfig from '../data/appConfig.json';
import SiteFooter from '../components/pageLayout/SiteFooter';

const BrandCountryPageTemplate = ({ pageContext }) => {
    const intl = useIntl();
    const { multiCountry } = appConfig;
    const {
        brandProductsOnStage,
        setBrandOnStage,
        productTypeOnStage,
        searchFilterTypes,
        setBrandDetailsOnStage,
        setBrandProductsOnStage,
        brandDetailsOnStage,
    } = useContext(BrandContext);

    const theme = useContext(ThemeContext);
    const [brandHeaderHeight, setBrandHeaderHeight] = useState();
    const hasMounted = useHasMounted();
    const headerBind = useRef();

    const { brand, country } = pageContext;

    const [brandApi, setBrandApi] = useState();

    useEffect(() => {
        setBrandDetailsOnStage({
            ...brandDetailsOnStageInitialState,
        });
        setBrandProductsOnStage(undefined);
    }, []);

    useEffect(() => {
        if (brand && country) {
            const getBrand = async () => {
                const response = await brandUtils.brandApi(brandUtils.getBrandSlug(brand), country);
                setBrandApi(response);
            };
            getBrand();
        }
    }, [brand, country]);

    const { isLoadingSubscription, isErrorSubscription, userActiveGroups, username } = useAppUser();

    const [productsByRetailerByUser, setProductByRetailerByUser] = useState([]);
    const [searchNarrowProductTypesByUser, setSearchNarrowProductTypesByUser] = useState([]);

    useEffect(() => {
        if (brandApi) {
            setProductByRetailerByUser(
                brandUtils.productsByCountryByUserPreferences({
                    productsByRetailer: brandApi.productsByRetailer,
                    activeGroups: userActiveGroups || [],
                }),
            );
        }
    }, [brandApi, userActiveGroups]);

    useEffect(() => {
        setBrandOnStage(brand);
    }, [brand]);

    useEffect(() => {
        const availableProducts = [];
        const availabelProductTypes = [];

        availableProducts.push(productsByRetailerByUser);

        availableProducts.forEach((products) => {
            const filteredAvailableProducts = brandUtils.productByProductTypeOnStage(
                products,
                productTypeOnStage,
            );

            filteredAvailableProducts.forEach((product) =>
                availabelProductTypes.push(...product.intlKeywords),
            );
        });

        const count = {};
        const countArray = [];

        availabelProductTypes.forEach((item) => {
            count[item] = (count[item] || 0) + 1;
        });

        Object.entries(count).forEach(([countKey, countValue]) => {
            countArray.push({ keyword: countKey, amount: countValue });
        });

        const setPriorityByAZ = countArray.sort((a, b) => a.keyword.localeCompare(b.keyword, 'en'));
        const priorityByAZKeywords = setPriorityByAZ.map((item) => item.keyword);
        const narrowProductTypesAZ = priorityByAZKeywords.filter((item) =>
            productTypeList.includes(item.toLowerCase()),
        );
        setSearchNarrowProductTypesByUser(
            searchFilterTypes
                ? narrowProductTypesAZ.filter((item) => item.includes(searchFilterTypes))
                : narrowProductTypesAZ,
        );
    }, [searchFilterTypes, productTypeOnStage, productsByRetailerByUser, brandDetailsOnStage]);

    const checkBrandHeaderHeight = () => {
        setBrandHeaderHeight(headerBind.current?.getBoundingClientRect().height);
    };

    useEffect(() => {
        setBrandHeaderHeight(headerBind.current?.getBoundingClientRect().height);
    }, [searchNarrowProductTypesByUser, headerBind.current]);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.addEventListener('resize', checkBrandHeaderHeight);
            return () => window.removeEventListener('resize', checkBrandHeaderHeight);
        }
        return null;
    }, []);

    useEffect(() => {
        setBrandDetailsOnStage({
            brand,
            country: countryUtils.displayCountryName(intl, country),
            products: brandUtils.productByProductTypeOnStage(
                productsByRetailerByUser,
                productTypeOnStage,
            ),
        });
        setBrandProductsOnStage(productsByRetailerByUser);
    }, [productsByRetailerByUser, productTypeOnStage]);

    const renderBrandHeaderTitle = multiCountry
        ? intl.formatMessage(
              { id: 'brand.multiCountry.title' },
              {
                  brand: (
                      <GatsbyA
                          to={`/${brandUtils.getBrandSlug(brand)}`}
                          onClick={() => {
                              setBrandDetailsOnStage({
                                  ...brandDetailsOnStageInitialState,
                              });
                              setBrandProductsOnStage(undefined);
                          }}
                      >
                          <BrandTitle>{brand}</BrandTitle>
                      </GatsbyA>
                  ),
                  country: countryUtils.displayCountryName(intl, country),
              },
          )
        : intl.formatMessage(
              { id: 'brand.country.title' },
              {
                  brand: <BrandTitle>{brand}</BrandTitle>,
              },
          );

    const renderBrand = (
        <>
            <BrandHeaderOffset ref={headerBind}>
                <TransitionItem inProp appear mountOnEnter unmountOnExit>
                    <BrandHeader
                        title={renderBrandHeaderTitle}
                        productTypeList={searchNarrowProductTypesByUser}
                    />
                </TransitionItem>
            </BrandHeaderOffset>
            <BrandDetails offset={brandHeaderHeight} />
        </>
    );

    const renderBgColor = hasMounted ? theme.site.stage.backgroundColor : null;

    if (isLoadingSubscription && hasMounted) {
        return <Loader isFullScreen fullScreenBackgroundColor={theme.site.stage.backgroundColor} />;
    }

    if (username && isErrorSubscription) {
        return (
            <AlertFeedback
                inProp
                alertType="danger"
                alertMessage={intl.formatMessage({ id: 'user.isErrorSubscription' })}
            />
        );
    }

    if (!brandApi && hasMounted) {
        return <Loader isFullScreen fullScreenBackgroundColor={theme.site.stage.backgroundColor} />;
    }

    return (
        <>
            <HelmetDetails
                title={
                    multiCountry
                        ? intl.formatMessage(
                              { id: 'brand.multiCountry.title' },
                              {
                                  brand: brand.toUpperCase(),
                                  country: countryUtils.displayCountryName(intl, country),
                              },
                          )
                        : intl.formatMessage(
                              { id: 'brand.country.title' },
                              {
                                  brand: brand.toUpperCase(),
                              },
                          )
                }
            />
            <DefaultTemplate
                bgStart={renderBgColor}
                bgEnd={renderBgColor}
                isFullWidth
                content={
                    <Container isFullHeight>
                        <Row isFullHeight>
                            <Col xxs={30}>{hasMounted && renderBrand}</Col>
                        </Row>
                    </Container>
                }
                footer={<SiteFooter inProp={!brandProductsOnStage} />}
            />
        </>
    );
};

BrandCountryPageTemplate.propTypes = {
    pageContext: pageContextPropTypes.brandCountryPageContext.isRequired,
};

export default BrandCountryPageTemplate;
